import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Text } from 'wix-ui-tpa';
import { classes, st } from './Details.st.css';
import { AlignmentOptions, ISection, SectionTypes } from '../../types';
import Section from '../Section';
import { DetailsSectionViewModel } from '../../../../service-page-view-model/details-section-view-model/detailsSectionViewModel';
import { useGlobalAlignment } from '../useGlobalAlignment';
import settingsParams from '../../settingsParams';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

type DetailsProps = {
  viewModel?: DetailsSectionViewModel;
  section: ISection;
  className?: string;
};

const DetailsComponent: React.FC<DetailsProps> = ({
  viewModel,
  section,
  className,
}) => {
  const { isMobile, isRTL } = useEnvironment();
  const settings = useSettings();
  const globalAlignment = useGlobalAlignment();
  const buttonAlignment =
    settings.get(settingsParams.detailsBoxAlignment) ===
    AlignmentOptions.STRETCH
      ? settings.get(settingsParams.detailsButtonAlignment)
      : settings.get(settingsParams.detailsBoxAlignment);
  const textAlignment =
    (settings.get(settingsParams.detailsBoxAlignment) !==
    AlignmentOptions.STRETCH
      ? settings.get(settingsParams.detailsBoxAlignment)
      : settings.get(settingsParams.detailsTextAlignment)) || globalAlignment;
  const boxWidth =
    settings.get(settingsParams.detailsBoxAlignment) ===
    AlignmentOptions.STRETCH
      ? '100%'
      : undefined;
  const withBookButton = section.bookButton && viewModel?.isBookable;
  const showDuration =
    settings.get(settingsParams.detailsDurationVisibility) &&
    viewModel?.duration;
  const showPrice =
    settings.get(settingsParams.detailsPriceVisibility) && viewModel?.price;
  const showLocation =
    settings.get(settingsParams.detailsLocationVisibility) &&
    viewModel?.location;
  const showLocations =
    settings.get(settingsParams.detailsLocationVisibility) &&
    viewModel?.locations?.length;

  const content: JSX.Element[] = [];
  if (showDuration) {
    content.push(
      <Text
        key="details-duration"
        className={classes.box}
        data-hook="details-duration"
        tagName="li"
      >
        <span aria-hidden>{viewModel?.duration}</span>
        <span
          className={classes.srOnly}
          data-hook="details-duration-aria-label"
        >
          {viewModel?.durationAria}
        </span>
      </Text>,
    );
  }
  if (showPrice) {
    content.push(
      <li key="details-price">
        <div className={classes.srOnly} data-hook="sr-only-details-price">
          {viewModel?.ariaPrice}
        </div>
        <div aria-hidden={true} data-hook="aria-hidden-details-price">
          <Text className={classes.box} data-hook="details-price" tagName="div">
            {viewModel?.price}
          </Text>
        </div>
      </li>,
    );
  }
  if (showLocation) {
    content.push(
      <Text
        key="details-location"
        className={classes.box}
        data-hook="details-location"
        tagName="li"
      >
        {viewModel?.location}
      </Text>,
    );
  }

  if (showLocations) {
    content.push(
      <Text
        key="details-locations"
        className={classes.box}
        data-hook="details-locations"
        tagName="li"
      >
        {viewModel?.locations?.map((location, index) => {
          return (
            <>
              {location}
              {index !== Number(viewModel?.locations?.length) - 1 && (
                <span className={classes.locationDivider}>|</span>
              )}
            </>
          );
        })}
      </Text>,
    );
  }

  return withBookButton || !!content.length ? (
    <Section
      sectionType={SectionTypes.DETAILS}
      withBookButton={withBookButton}
      buttonAlignment={buttonAlignment}
      childrenAlignment={settings.get(settingsParams.detailsBoxAlignment)}
    >
      <ul
        style={{ width: boxWidth }}
        className={st(
          classes.root,
          { textAlignment, isMobile, isRTL },
          className,
        )}
        data-hook="details-wrapper"
      >
        {content}
      </ul>
    </Section>
  ) : null;
};

export default DetailsComponent;
