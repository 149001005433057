
  import React from 'react';
  import { getWidgetWrapper } from '@wix/yoshi-flow-editor/runtime/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/work/e47ef567209736c/packages/bookings-service-details-widget/src/components/BookingServicePage/Widget/index.tsx';

  import {
    withStyles
  } from '@wix/native-components-infra';

  

  import { initI18nWithoutICU as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
import { createExperiments, createWidgetExperiments } from '@wix/yoshi-flow-editor/runtime/esm/experiments';
    

  
import { I18nextProvider } from '@wix/yoshi-flow-editor/i18n';


      import { PureExperimentsProvider } from '@wix/yoshi-flow-editor';
      var ExperimentsProvider = React.Fragment;
      

import { BILoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/BILogger/BILoggerProvider';

import { FedopsLoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/FedopsLoggerProvider';

import { HttpProvider } from '@wix/yoshi-flow-editor';

  import { TPAComponentsProvider } from 'wix-ui-tpa';

var BaseUiEnvironmentProviderWrapper = React.Fragment;

var providers = {
  I18nextProvider,
  ExperimentsProvider,
  PureExperimentsProvider,
  BILoggerProvider,
  FedopsLoggerProvider,
  HttpProvider,
  TPAComponentsProvider,
  BaseUiEnvironmentProviderWrapper,
}


  import stylesParams from '/home/builduser/work/e47ef567209736c/packages/bookings-service-details-widget/src/components/BookingServicePage/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://dd0a55ccb8124b9c9d938e3acf41f8aa@sentry.wixpress.com/514',
      id: 'dd0a55ccb8124b9c9d938e3acf41f8aa',
      projectName: 'bookings-service-details-wi-wu',
      teamName: 'bookings',
      
    };

  var translationsConfig = {"icuEnabled":false,"defaultTranslationsPath":"/home/builduser/work/e47ef567209736c/packages/bookings-service-details-widget/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hu","id","it","ja","ko","lt","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var UserComponent = getWidgetWrapper({
      initI18n,
      withStylesHoc: withStyles,
      createExperiments,
      createWidgetExperiments,
      providers,
    }, Widget, {
    sentryConfig,
    translationsConfig,
    stylesParams,
    componentId: 'a91a0543-d4bd-4e6b-b315-9410aa27bcde',
    name: 'BookingServicePage',
    withErrorBoundary: false,
    localeDistPath: 'assets/locales',
  });

  
    import { hot } from '@wix/component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    
    component: UserComponent,
    loadChunks
  };
