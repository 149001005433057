import React from 'react';
import { TextButton } from 'wix-ui-tpa';
import { st, classes } from './LoadAllButton.st.css';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import settingsParams from '../../BookingServicePage/settingsParams';

export type LoadAllButtonProps = {
  onClick;
};
export const LoadAllButton = ({ onClick }: LoadAllButtonProps) => {
  const settings = useSettings();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const loadAllText =
    settings.get(settingsParams.scheduleLoadAllText) ||
    t('app.scheduling.load-all-sessions.default');
  return (
    <TextButton
      className={st(classes.root, { isMobile })}
      onClick={onClick}
      data-hook="load-all-button"
    >
      {loadAllText}
    </TextButton>
  );
};
