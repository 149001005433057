import React from 'react';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { st, classes } from './SchedulingAgenda.st.css';
import { DailySessions } from './DailySessions/DailySessions';
import {
  SchedulingSectionStatus,
  SchedulingSectionViewModel,
} from '../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import { Loader } from './Loader/Loader';
import { EmptyState } from './EmptyState/EmptyState';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import settingsParams from '../BookingServicePage/settingsParams';
import Divider from './Divider/Divider';
import { useLoadAllSessions } from './useLoadAllSessions';
import { CurrentLocationProvider } from './useCurrentLocation';

export type SchedulingAgendaProps = {
  viewModel?: SchedulingSectionViewModel;
  currentLocation?: string;
};
export const SchedulingAgenda: React.FC<SchedulingAgendaProps> = ({
  viewModel,
  currentLocation,
}) => {
  const settings = useSettings();
  const { t } = useTranslation();
  const { isRTL } = useEnvironment();
  const totalNumberOfSessions = viewModel?.schedulingDaysViewModel?.length || 0;
  const { numberOfVisibleSessions, LoadAllSessions } = useLoadAllSessions({
    defaultValue: settingsParams.scheduleDays,
    totalNumberOfSessions,
  });

  const componentByStatus = () => {
    switch (viewModel?.status) {
      case SchedulingSectionStatus.LOADING:
        return <Loader />;
      case SchedulingSectionStatus.EMPTY:
        return emptyState();
      case SchedulingSectionStatus.FAILED:
        return (
          <EmptyState
            message={t('app.widget.uou-messages.scheduling-error')}
            dataHook="error-message"
          />
        );
      case SchedulingSectionStatus.SUCCESS:
        return dailySessions();
      default:
        return <Loader />;
    }
  };
  const emptyState = () => {
    const daysAhead = settings.get(settingsParams.scheduleDays);
    const emptyStateMessage =
      settings.get(settingsParams.scheduleEmptyStateText) ||
      t('app.scheduling.empty-state', { daysAhead });
    return <EmptyState message={emptyStateMessage} dataHook="empty-state" />;
  };

  const showDivider = (index) => {
    const lastElementIndex =
      Math.min(numberOfVisibleSessions, totalNumberOfSessions) - 1;
    return index !== lastElementIndex ? <Divider /> : null;
  };

  const dailySessions = () => {
    return (
      <ul className={classes.list}>
        {viewModel?.schedulingDaysViewModel
          ?.slice(0, numberOfVisibleSessions)
          .map((scheduleDay, index) => (
            <>
              <DailySessions viewModel={scheduleDay} key={index} />
              {showDivider(index)}
            </>
          ))}
        <LoadAllSessions />
      </ul>
    );
  };

  return (
    <CurrentLocationProvider value={currentLocation}>
      <div
        className={st(classes.root, { isRTL })}
        data-hook="scheduling-agenda"
      >
        {componentByStatus()}
      </div>
    </CurrentLocationProvider>
  );
};
